import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white">
      <h1 className="text-3xl font-bold text-[#00BCD4]">NLDEV</h1>
      <h1 className="text-[#00BCD4]">Desarrolladores de Nuevo Laredo</h1>
    </div>
  );
};

export default Navbar;
