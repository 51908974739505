import React, {useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AiOutlineSend } from 'react-icons/ai';
import emailjs from 'emailjs-com';
emailjs.init('TROlLrnNSkDZGNKhv');
const Formulario = () => {
	const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
    const [formularioNoEnviado, cambiarFormularioNoEnviado] = useState(false);
	return (
    <div>
      <div className="p-2">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 lg:gap-2">
          <div className="p-3 rounded-md flex justify-center">
            <div className="w-full">
              <h1 className="md:text-6xl sm:text-6xl text-3xl font-bold text-white md:pb-6 text-center">
                Contactanos!
              </h1>
              <h3 className="md:text-2xl sm:text-2xl text-2xl text-white py-6 md:text-justify text-center">
                Nosotros escucharemos tus necesidades en la innovación,
                automatización de tus procesos o alguna idea que tengas para
                mejorar en tu productividad de tu negocio, aprovechando nuestra
                vanguardia en la tecnología.
              </h3>
            </div>
          </div>

          <div className="p-3 rounded-md flex items-center justify-center">
          <Formik
               initialValues={{
                nombre: '',
                correo: '',
                mensaje: ''
               }}

               validate={(valores) => {
                let errores = {};

                // VALIDACION NOMBRE
                if (!valores.nombre) {
                    errores.nombre = "Por favor ingresa un nombre.";
                }else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.nombre)) {
                    errores.nombre = "El nombre solamente puede contener letras y espacios.";
                }

                // VALIDACION CORREO
                if (!valores.correo) {
                    errores.correo = "Por favor ingresa un correo electrónico.";
                }else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(valores.correo)) {
                    errores.correo = "El correo solo puede contener letras, números, puntos, guiones y guion bajo.";
                }

                // VALIDACION MENSAJE
                if (!valores.mensaje) {
                    errores.mensaje = "Por favor ingresa un mensaje.";
                }else if (!/^[a-zA-ZÀ-ÿ0-9_.+-?\s]{1,500}$/.test(valores.mensaje)) {
                    errores.mensaje = "El mensaje solo puede contener letras, números, puntos, guiones y guion bajo.";
                }

                return errores;
               }}

               onSubmit={(valores, {resetForm}) =>{
                
                emailjs.send("service_fou4e8i","template_fvxi3ol",{
                nombre: valores.nombre,
                correo: valores.correo,
                mensaje: valores.mensaje
                }).then(() => {

                    resetForm();
                    cambiarFormularioEnviado(true);
                    setTimeout(() => cambiarFormularioEnviado(false), 9000);

                    }, (err) => {

                    resetForm();
                    cambiarFormularioNoEnviado(true);
                    setTimeout(() => cambiarFormularioNoEnviado(false), 9000);

                    });

               }}
          >

            {( {errors} ) => (
            <Form className="formulario w-full">
              <div>
                <Field
                  id="nombre"
                  name="nombre"
                  placeholder="Nombre"
                />

               <ErrorMessage name='nombre' component={() => (
                    <div className='error'>{errors.nombre}</div>
                )} />
              </div>

              <div>
                <Field
                  id="correo"
                  name="correo"
                  placeholder="Correo"
                />
                <ErrorMessage name='correo' component={() => (
                    <div className='error'>{errors.correo}</div>
                )} />
              </div>

              <div>
                <Field
                  as="textarea"
                  id="mensaje"
                  name="mensaje"
                  placeholder="Mensaje"
                />
                <ErrorMessage name='mensaje' component={() => (
                    <div className='error'>{errors.mensaje}</div>
                )} />
              </div>

              <button type="submit">
                Enviar{" "}
                <AiOutlineSend
                  style={{
                    display: "inline",
                    fontSize: "20px",
                    fill: "white",
                  }}
                />
              </button>
              {formularioEnviado && <p className='exito'>Gracias por contactarnos, en un lapso de 24-48 hrs estaremos en contacto!</p>}

              {formularioNoEnviado && <p className='errorMensaje'>Surgió un error, inténtalo de nuevo o más tarde.</p>}
            </Form>
            )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default Formulario;